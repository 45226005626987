<template>
  <div class="supplydemand">
    <div class="supplydemand-left">
      <div class="supplydemand-left-header">
        <div class="info_header_left">我收藏的专家</div>
        <div class="message-totle">共 {{ total }} 条</div>
      </div>

      <div class="user-list-box">
        <div class="" v-for="item in expertList" @click="goexpertDetails(item)">
          <div class="user-list-items">
            <div class="header-img">
              <img :src="item.photo" />
            </div>
            <div class="message">
              
              <div>
                {{ item.name }} <span>{{ item.post }}</span>
              </div>
              <div>
                <i class="iconfont icon-gongsichaxun"></i>
                所在公司：{{ item.company }}
              </div>
            </div>
            <div class="textarea-date-box">
              <div class="Operationtype">
                <!-- <div @click.stop="clickExpertLike(item.id)">
                  <img :src="imgIcon.imgZan" />
                  <span class="count">{{ item.like }}</span>
                </div> -->
                <div @click.stop="clickExpertFav(item.id)">
                 <span class="compant_shoucang" >
                <img :src="imgIcon.imgCangTrue" />
                <span>已收藏</span>
              </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  expertFavList,
  expertLike,
  expertFav,
  expertLikeDown,
  expertFavDown,
} from "@/api";
import { imgObj } from "@/utils/const.js";
export default {
  name: "supplydemand",
  data() {
    return {
      queryParams: {
        //列表搜索参数
        pageIndex: 1,
        pageSize: 10,
        userId: localStorage.getItem("userId") - 0,
      },
      expertList: [],
      classifylist: [],
      resultlist: [],
      currentPage: 1,
      peopletype: "最新加入",
      Supplylist: [],
      total: 0,
      hotsupplylist: [],
      dialogVisible: false,
      imgIcon: imgObj,
    };
  },
  methods: {
    getExpertList() {
      expertFavList(this.queryParams).then((res) => {
        console.log("专家", res);
        this.expertList = res.data.data.item1;
        this.total = res.data.data.item2;
      });
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    showrelease() {
      let token = window.localStorage.getItem("token");
      let username = window.localStorage.getItem("userName");

      this.$router.push("/release");

      // if (token && username) {
      //   this.$router.push("/release");
      // } else {
      //   this.$message({
      //     message: "请先登录后，才能发布供需",
      //     type: "warning",
      //   });
      //   setTimeout(() => {
      //     location.reload();
      //   }, 1000);
      // }
    },
    getFav() {
      fav().then((res) => {
        console.log(res);
        this.Supplylist = res.data;
        // this.total = this.Supplylist.length;
      });
    },
    handleSizeChange(e) {
      console.log(e);
    },
    handleCurrentChange(e) {
      console.log(e);
    },
    changefirstbtn(item, index) {
      this.classifytext = item.title;
      this.resultlist = this.classifylist[index].children;
      this.resulttext = "全部";
      this.changelist(item.id);
    },
    changesecondbtn(item) {
      this.resulttext = item.title;
      this.changelist(item.id);
    },
    changelist(id) {
      getSupplyclassifyid(id).then((res) => {
        this.Supplylist = res.data;
        this.total = this.Supplylist.length;
      });
    },
    clickExpertLike(expertId) {
      let username = window.localStorage.getItem("userName");
      console.log(username);
      if (username) {
        let data = {
          userId: 4,
          expertId: expertId,
        };
        expertLike(data).then((res) => {
          console.log(res);
          if (res.data.code == "400") {
            expertLikeDown(data).then((res) => {
              this.getExpertList();
            });
          } else {
            this.getExpertList();
          }
        });
      } else {
        // this.$router.push('/login')
        this.$message({
          message: "请先登陆才能点赞",
          type: "warning",
        });
      }
    },
    clickExpertFav(expertId) {
      let username = window.localStorage.getItem("userName");
      console.log(username);
      if (username) {
        let data = {
          userId: 4,
          expertId: expertId,
        };
        expertFav(data).then((res) => {
          console.log(res);
          if (res.data.code == "400") {
            expertFavDown(data).then((res) => {
              this.getExpertList();
            });
          } else {
            this.getExpertList();
          }
        });
      } else {
        // this.$router.push('/login')
        this.$message({
          message: "请先登陆才能点赞",
          type: "warning",
        });
      }
    },
    goexpertDetails(item) {
      console.log(item);
      localStorage.setItem("secondpath", JSON.stringify(this.$route.path));
      // this.$router.push(`/DetailsPage?id=${item.id}&type=${type}`)
      let routeData = this.$router.resolve({
        name: "expertDetails",
        query: {
          id: item.id,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    this.getExpertList();
  },
};
</script>

<style lang="less" scoped>
.supplydemand {
  //   width: 1280px;
  padding: 0.625rem 0.9375rem;
  display: flex;
  justify-content: space-between;
  .supplydemand-left {
    width: 100%;
    margin-right: 20px;
    .supplydemand-left-header {
      height: 48px;
      line-height: 48px;
      font-size: 18px;
      font-weight: 600;
      color: #2e2e2e;
      padding-left: 10px;
      display: flex;
      justify-content: space-between;
    }
    .message-totle {
      // padding: 20px;
      font-size: 14px;
      color: #989898;
      text-align: right;
    }
    .user-list-box {
      padding: 20px;
      background: white;
      .user-list-items {
        padding: 20px 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        .header-img {
          width: 100px;
          margin-right: 50px;
          margin-left: 13px;
          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }
        }
        .message {
          width: 100%;
          div:nth-child(1) {
            font-size: 20px;
            font-weight: bold;
            span {
              padding: 5px 16px;
              font-size: 14px;
              color: #6a6a6a;
              margin-left: 20px;
              background: #f5f6f6;
            }
          }
          div:nth-child(2) {
            margin-top: 15px;
            font-size: 14px;
            color: #9a999e;
          }
        }
        .textarea-date-box {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          span:nth-child(2) {
            font-size: 14px;
            color: #999;
          }
          .Operationtype {
            display: flex;
            font-size: 14px;
            color: #999;
            cursor: pointer;
            .compant_shoucang {
            display: flex;
    align-items: center;
            border: 0.0625rem solid #ededed;
            padding: 0.6rem 1.2rem;
            background: #fff;
            color: #525252;
            font-size: 0.875rem;
            font-weight: 400;
            margin: auto;
          }
          div {
            // padding: 10px 25px;
            // background: #fff;
            color: #525252;
            font-size: 14px;
            margin-right: 20px;
            // border: 1px solid #ededed;
            display: flex;
            align-items: flex-end;
            span {
              margin-left: 10px;
              min-width: 44px;
            }
          }
            // div:nth-child(1) {
            //   padding: 10px 20px;
            //   background: #f5f6f6;
            //   color: #314483;
            //   font-size: 14px;
            // }
            // div {
            //   padding: 10px;
            //   margin-right: 20px;
            // }
            .count {
              margin-left: 10px;
            }
          }
        }
        .friends {
          width: 100px;
          color: white;
          background: #314483;
          font-size: 16px;
          padding: 10px 31px;
          border-radius: 29px;
          text-align: center;
          cursor: pointer;
          .iconfont {
            margin-right: 10px;
          }
        }
      }
      .user-list-items:hover{
        background: #e9e9e973;
      }
    }
  }

  .el-pagination {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    /deep/ li {
      margin: 0 8px !important;
      min-width: 36px !important;
      height: 34px;
      line-height: 36px;
    }
    /deep/ .active {
      color: white;
      background: #314483;
    }
    /deep/ li:hover {
      color: white;
      background: #314483;
    }
    /deep/ button {
      margin: 0 8px !important;
      height: 34px;
      line-height: 36px;
    }
    /deep/ .btn-next {
      padding: 0;
    }
    /deep/ .btn-prev {
      padding: 0;
    }
    /deep/ button:hover {
      color: white;
      background: #314483;
    }
    /deep/ span {
      margin-right: 30px;
      line-height: 36px;
    }
  }
}
</style>